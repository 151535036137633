// Import the searchNewsArticles query
import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { searchNewsArticles } from "@/graphql/queries";
import { getAWSStorageAsset } from '@/services/storage';

export const fetchSimilarArticles = async (tags, excludeArticleId,limit = 4) => {
    try {
      const variables = {
        filter: {
          tags: {
            match: tags,
          },
          id: {
            ne: excludeArticleId,
          },
        },
        limit,  // Limit the number of articles to 4
            sort: [
              {
                field: "publishedAt",
                direction: "desc",  // Sort by 'publishedAt' in descending order
              },
            ],
      };
      const result = await API.graphql({ query: searchNewsArticles, variables , authMode: GRAPHQL_AUTH_MODE.API_KEY
      });
      
      let similarArticles = result.data.searchNewsArticles.items;
      similarArticles = await Promise.all(
        similarArticles.map(async (article) => {
          const articleImage = await getAWSStorageAsset(`NewsArticle/${article.id}`);
          article.image = articleImage;
          return article;
        })
      );
      return similarArticles;
    } catch (error) {
      console.error("An error occurred while fetching similar articles:", error);
      return [];
    }
  };