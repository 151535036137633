import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { getNewsArticle } from '@/graphql/queries'; // Define the appropriate query for getting a single news article
import { getAWSStorageAsset } from '../storage.js';

export const getNewsDetailById = async (newsId) => {
    try {
        const newsDetail = await API.graphql({
            ...graphqlOperation(getNewsArticle, { id: newsId }),
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        });
        const news = newsDetail.data.getNewsArticle;
        if (news) {
          if(news.imageFilename != " " && news.imageFilename.length > 0 ) {
            const newsImage = await getAWSStorageAsset(`NewsArticle/${news.imageFilename[0]}`);
            news.image = newsImage;
          }
          if(news.author && news.author.imageFilename != " " && news.author.imageFilename.length > 0 ) {

            let authorImage = null;
            if (news.author && news.author.id) {
              authorImage = await getAWSStorageAsset(`Author/${news.author.imageFilename[0]}`);
            }
      
            if (authorImage) {
              news.author.image = authorImage;
            }
          }
            return news;
          } else {
            return null; // Return null if no news article is found
          }
        } catch (error) {
          console.error("An error occurred while fetching news detail:", error);
          return null; // Return null on error
        }
};