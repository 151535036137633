<template>
	<div class="dropdown" ref="dropdown">
		<v-btn
			id="share-button"
			class="ma-2 share-button"
			@click="toggleDropdown"
			text
		>
			<!-- <v-icon>mdi-upload</v-icon> -->
			<v-img
				:src="shareIcon"
				style="width: 14px; height: 14px"
				class="mr-2 icon"
			/>
			Share
		</v-btn>
		<div v-if="isOpen" class="dropdown-content mt-1">
			<ul>
				<li @click="copyLink">
					<p v-if="showCopy" class="d-inline" id="copy-link">
						Copy Link <v-icon class="pl-1 icon">mdi-content-copy</v-icon>
					</p>
					<p v-if="!showCopy" class="d-inline" style="color: green">
						Copied!
						<v-icon style="color: green" class="pl-1 icon"
							>mdi-check-circle</v-icon
						>
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		link: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isOpen: false,
			showCopy: true,
			shareIcon: require("@/assets/svgs/Share-08.svg"),
		};
	},
	methods: {
		toggleDropdown() {
			this.isOpen = !this.isOpen;
		},
		handleClickOutside(event) {
			if (!this.$refs.dropdown.contains(event.target)) {
				this.isOpen = false;
			}
		},
		copyLink() {
			navigator.clipboard.writeText(this.link);
			this.showCopy = false;
			setTimeout(() => {
				this.showCopy = true;
				this.isOpen = false;
			}, 2000);
		},
	},
	mounted() {
		document.addEventListener("click", this.handleClickOutside);
	},
};
</script>

<style scoped>
.dropdown {
	position: relative;
	display: inline-block;
}
.icon {
	font-size: 14px;
	color: #808080;
}
.share-button {
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-family: Roboto;
	padding: 10px 20px !important;
	font-style: bold;
	color: #808080;
}

.dropdown-content {
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 5px;
}

.dropdown-content ul {
	list-style-type: none;
	padding: 0;
	color: black;
	margin-bottom: 0px !important;
}
.dropdown-content ul li p {
	font-size: 14px;
}
.dropdown-content ul li {
	padding: 8px 6px;
	cursor: pointer;
	text-align: left;
	border-bottom: 1px solid #ddd;
	color: black;
	font-family: Roboto;
}

.dropdown-content ul li:hover {
	background-color: #f1f1f1;
}

.dropdown-content ul li:last-child {
	border-bottom: none;
}

:deep .v-btn:not(.v-btn--round).v-size--default {
	height: 36px;
	min-width: 30px;
	padding: 6px;
}
</style>
